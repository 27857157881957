const appendScript = (url, onReadyCallback) => {
  const targetScript = document.createElement('script')
  targetScript.type = 'text/javascript'
  if (targetScript.readyState) { // IE
    targetScript.onreadystatechange = () => {
      if (targetScript.readyState === 'loaded'
        || targetScript.readyState === 'complete') {
        targetScript.onreadystatechange = null
        onReadyCallback()
      }
    }
  } else { // Non IE
    targetScript.onload = () => {
      onReadyCallback()
    }
  }

  targetScript.src = url
  document.head.appendChild(targetScript)
}

export default appendScript
