const scripts = [...document.getElementsByTagName('script')].filter(e => e && e.src)
const sdkScripts = scripts.filter(ee => ee.src.match(/passport-ui-widget/i))
const sdkScript = new URL(sdkScripts[sdkScripts.length - 1].src)

const getSDKURL = () => {
  const passportPathUrl = new URL(sdkScript.href)
  return {
    passportPathUrl: new URL(passportPathUrl.href.replace(/(\/passport-ui-widget\/v4\/)[^?]+(.*)$/i, '$1passportUrl.js$2')),
  }
}

export default getSDKURL
